import { styled, Typography, Box } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 32px 20px;
  min-width: 708px;

  @media (min-width: 768px){
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const ScrollWrapper = styled(Box)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  @media (min-width: 768px){
    overflow: visible;
  }
`;

export const Header = styled(Box)`
  display: flex;
  width: 100%;
`;

export const Col = styled(Box)`
  display: flex;
  text-align: center;
  padding: 11px 5px;
  position: relative;
  z-index: 1;
  width: 100%;
  background-image: linear-gradient(90deg, #1f1f1f 0%, #1f1f1f 100%);
  background-repeat: no-repeat;

  &:first-child{
    width: 56%;
    text-align: left;
    flex-shrink: 0;

    @media (min-width: 640px){
      width: 50%;
    }
  }

  &:nth-child(1){
    background-size: calc(100% - 20px) 1px;
    background-position: 0 0;

    @media (min-width: 768px){
      background-size: calc(100% - 30px) 1px;
    }
  }

  &:nth-child(3){
    background-size: calc(100% - 40px) 1px;
    background-position: 20px 0;
    margin-right: -20px;

    @media (min-width: 768px){
      margin-right: -32px;
      background-size: calc(100% - 60px) 1px;
      background-position: 30px 0;
    }
  }

  &:nth-child(2){

    &:before,
    &:after{
      content: '';
      position: absolute;
    }

    &:before{
      z-index: -1;
      background-color: #141414;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: solid #E3C050;
      border-width: 0 1px;
    }

    &:after{
      bottom: 0;
      z-index: 1;
      height: 1px;
      background-image: linear-gradient(90deg, #1f1f1f 0%, #1f1f1f 100%);
      left: 20px;
      right: 20px;

      @media (min-width: 768px){
        left: 30px;
        right: 30px;
      }
    }
  }

  .head &{
    background-size: 0;

    &:before{
      top: -32px;
      border-radius: 11px 11px 0 0;
      border-top-width: 1px;
    }
  }

  .body-row:last-child &{

    &:nth-child(2){

      &:before{
        bottom: -32px;
        border-bottom-width: 1px;
        border-radius: 0 0 11px 11px;
      }

      &:after{
        opacity: 0;
      }
    }
  }
`;

export const AlignBox = styled(Box)`
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
`;

export const HeaderHeading = styled(Typography)`
  color: #fff;
  font-size: 15px;
  line-height: 1.1;
  font-weight: 700;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const ImageHolder = styled(Box)`
  width: 100%;
  max-width: 60px;
  margin: 0 auto -10px;

  @media (min-width: 768px){
    max-width: 200px;
  }
  
  img{
    max-width: 100%;
    height: auto;
  }
`;

export const Body = styled(Box)`
  display: flex;
  width: 100%;
`;

export const ItemHeading = styled(Typography)`
  color: #C0C0C0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;

  @media (min-width: 768px){
    font-size: 18px;
  }
`;

export const IconWrapper = styled(Box)`
  color: #FB1751;

  &.verified{
    color: #E3C050;
  }

  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;

    @media (min-width: 768px){
      width: 18px;
      height: 18px;
    }
  }
`;