import React from "react";
import Flex from "components/FlexWrapper";

const PageFooterContentExpertise = () => {
  return (
    <Flex className="ads6ix-layout-footer-content-left-row">
      <div className="ads6ix-layout-footer-content-left-row-content">
        <div className="ads6ix-layout-footer-content-left-row-content-heading">
          SHARE EXPERTISE
        </div>
        <div className="ads6ix-layout-footer-content-left-row-content-text">
          <div className="ads6ix-layout-footer-content-space">
            Tools to help you share expertise.
          </div>
          <div className="ads6ix-layout-footer-content-space">
            <ul>
              <li>
                Monetize your knowledge and build your personal brand on a
                global scale
              </li>
              <li>Share insights with a broader audience</li>
              <li>Build credibility and authority in your field</li>
              <li>Create new revenue streams from your expertise</li>
              <li>Access comprehensive data for analysis</li>
              <li>Engage directly with companies and investors</li>
            </ul>
          </div>
          <div className="ads6ix-layout-footer-content-space">
            Trusted by 100+ industry experts, newsletter writers and equity
            analysts.
          </div>
        </div>
      </div>
      <Flex className="ads6ix-layout-footer-content-left-row-menu">
        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Amplify
          </div>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Network
          </div>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Monetize
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageFooterContentExpertise;
