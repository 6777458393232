import React from "react";
import PageFooterAccess from "./PageFooterAccess";
import PageFooterBottom from "./PageFooterBottom";
import PageFooterContent from "./PageFooterContent";
import PageFooterDivider from "./PageFooterDivider";
import PageFooterMenu from "./PageFooterMenu";
import PageFooterTop from "./PageFooterTop";
import "./style.scss";

const PageFooter = () => {
  return (
    <div className="ads6ix-layout-footer">
      <PageFooterTop />
      <PageFooterDivider />
      <PageFooterAccess />
      <PageFooterDivider />
      <PageFooterMenu />
      <PageFooterDivider />
      <PageFooterContent />
      <PageFooterBottom />
    </div>
  );
};

export default PageFooter;
