import { styled, Box, Typography, Divider, Link } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 16px;
  overflow: hidden;

  &.slider-inner {
    background: #1f1f1f;
    border: 1px solid #373737;
    border-radius: 5px;
  }

`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 8px;
`;

export const HeadingLink = styled(Link)`
  color: #8C8E95;
  vertical-align: middle;
  margin-left: 8px;
  transition: color 0.3s ease;

  &:hover{
    color: inherit;
  }
  
  .MuiSvgIcon-root{
    width: 14px;
    height: 14px;
  }
`;

export const UrlLink = styled(Link)`
  color: #E3C050;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s ease;
  vertical-align: top;

  &:hover{
    color: #fff;
  }
`;

export const LabelTag = styled(Typography)`
  color: #000;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
  vertical-align: 0em;
  padding: 3px;
  border-radius: 6px;
  background-color: #E3C050;
  margin-right: 8px;
`;

export const DescriptionText = styled(Typography)`
  color: #8C8E95;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 8px;
`;