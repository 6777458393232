import { styled, Box } from '@mui/material';

export const StickyHeaderHolder = styled(Box)`
  background-color: black;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 7px 24px;
  font-size: 14px;
  text-align: center;
  display: flex;

  a {
    color: #E3C050;
    text-decoration: none;
    display: inline-flex;
    font-weight: normal;

    &:hover {
      text-decoration: underline;
    }
  }

`;
