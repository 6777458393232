import { styled, Box, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  padding: 20px;
  border-radius: inherit;
  background: #2b2b2b;
  box-shadow: 0 0 0 1px #373737;

  ul.custom-way {
    li {
      background: #373737;
    }
  }

  @media (min-width: 576px){
    padding-left: 32px;
    padding-right: 32px;
  }

  &.emphasized{
    background: #131313;
    box-shadow: 0 0 0 1px #E3C050;

    ul.custom-way {
      li {
        background: #1f1f1f;
      }
    }

    @media (min-width: 992px){
      padding: 36px 48px;
    }
  }

  &.bordered.featured{
    padding-top: 38px;

    h2, .h2{
      margin-bottom: 38px;
    }
  }
`;

export const Heading = styled(Typography)`
  font: 900 36px/1.167 'Playfair Display', 'Times New Roman', Times, serif;
  color: #8C8E95;
  margin-bottom: 16px;

  @media (min-width: 992px){
    font-size: 40px;
    margin-bottom: 22px;
  }
  @media (min-width: 1200px){
    font-size: 50px;
  }

  .emphasized &,
  .featured &{
    color: #E9E9E9;
  }
`;