import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import TwoColumnsGrid from "components/TwoColumnsGrid";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const PresentationSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="PresentationSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <TwoColumnsGrid sameheight="false">
        <CardBox>
          <FeaturesListColumn heading="The Old Way">
            <FeaturesList className="custom-way">
              <ListItem>
                <ListItemText
                  primary="Your investor advertising lacks precision, casting a wide net without measurable results."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Setting up and managing IR advertising campaigns is expensive, high-risk and performance is hard to measure."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="It's a challenge to differentiate between general ad views and genuine investor interest."
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
        <CardBox>
          <FeaturesListColumn heading="The New Way" status="active">
            <FeaturesList className="custom-way">
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Launch targeted campaigns with investor-centric focus, and measure concrete results, from investor meetings to email list growth."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Easily set campaign goals, upload creatives, and scale your budget based on real-time performance, with low minimum spend."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TickIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Directly engage with investors showing clear intent, from requesting meetings to downloading essential investor materials."
                />
              </ListItem>
            </FeaturesList>
          </FeaturesListColumn>
        </CardBox>
      </TwoColumnsGrid>
    </Wrapper>
  </Section>
);

export default PresentationSection;