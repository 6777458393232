import { styled, Typography, Box, Divider } from '@mui/material';

export const Section = styled('div')`
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  .headingHead{

    .MuiTypography-h2{

      @media (min-width: 1200px){
        font-size: 52px;
      }
    }
  }

  .two-columns{

    @media (min-width: 992px){
      gap: 36px;
    }

    > div{
      
      @media (min-width: 992px){
        width: calc(50% - 18px);
      }
    }
  }
`;

export const Wrapper = styled(Box)`
  width: 100%;
  max-width: 1091px;
  margin-left: auto;
  margin-right: auto;
`;

export const Richtext = styled(Box)`
  padding: 24px;

  @media (min-width: 992px){
    padding: 36px;
  }
`;

export const TextRow = styled(Box)`
  overflow: hidden;
`;

export const Heading = styled(Typography)`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 10px;

  @media (min-width: 768px){
    font-size: 20px;
  }
`;

export const Description = styled(Typography)`
  color: #C0C0C0;
  font-size: 16px;
  line-height: 1.35;
`;

export const CustomDivider = styled(Divider)`
  border: solid #373737;
  border-width: 1px 0 0;
  margin-top: 16px;
  margin-bottom: 16px;
`;