import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import HeaderLogo from "components/HeaderLogo";
import Flex from "components/FlexWrapper";

const PageFooterTop = () => {
  return (
    <Flex className="ads6ix-layout-footer-top">
      <HeaderLogo />
      <Flex className="ads6ix-layout-footer-top-social">
        <div className="ads6ix-layout-footer-top-social-text">
          Follow us on
        </div>
        <Link
          to="https://www.linkedin.com/company/6ix"
          className="ads6ix-layout-footer-top-social-link"
          target="_blank"
          title="Linkedin"
        >
          <LinkedIn />
        </Link>
        <Link
          to="https://www.facebook.com/6ixdotcom"
          className="ads6ix-layout-footer-top-social-link"
          target="_blank"
          title="Facebook"
        >
          <Facebook />
        </Link>
        <Link
          to="https://twitter.com/6ix"
          className="ads6ix-layout-footer-top-social-link"
          target="_blank"
          title="Twitter"
        >
          <Twitter />
        </Link>
        <Link
          to="https://www.instagram.com/6ixdotcom/"
          className="ads6ix-layout-footer-top-social-link"
          target="_blank"
          title="Instagram"
        >
          <Instagram />
        </Link>
      </Flex>
    </Flex>
  );
};

export default PageFooterTop;
