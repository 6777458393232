import { Box } from "@mui/material";

const Flex = ({ children, className, props }: any) => {
  return (
    <Box sx={{ display: "flex" }} className={className} {...props}>
      {children}
    </Box>
  );
};

export default Flex;
