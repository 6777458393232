import { useNavigate } from "react-router-dom";
import { StickyHeaderHolder } from "./style";

const StickyHeader = ({userInfo, handleClick}: any) => {
  const navigate = useNavigate();

  const handleRedirection = () => {  
    navigate("/lead");
  }

  return (
    <StickyHeaderHolder>
      <strong>Interested in Managing leads?</strong>
      <a href="" className="header-top-link" onClick={userInfo ? handleRedirection : handleClick}>Get started for FREE</a>
    </StickyHeaderHolder>
  );
};

export default StickyHeader;
