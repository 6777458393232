import LanguageTranslate from "components/LanguageTranslate/LanguageTranslate";

const PageFooterLanguage = () => {
 
  return (
    <LanguageTranslate />
  );
};

export default PageFooterLanguage;
