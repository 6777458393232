import ShadeBox from "components/ShadeBox";
import { Wrapper, Heading, HeadingLink, LabelTag, UrlLink, DescriptionText } from "./style";
import { ExternalLinkIcon } from "components/Icons/ExternalLinkIcon";

const HeadlineWidget = ({heading, url, urlLabel, description}: any) => (
  <ShadeBox>
    <Wrapper className="slider-inner">
      {heading &&
        <Heading
          variant="h2"
        >
          {heading}
          {url &&
            <HeadingLink href={url}>
              <ExternalLinkIcon />
            </HeadingLink>
          }
        </Heading>
      }
      {urlLabel &&
        <UrlLink
          href={url}
        >
          <LabelTag
            variant="caption"
          >
            Ad
          </LabelTag>
          {urlLabel}
        </UrlLink>
      }
      {description &&
        <DescriptionText>
          {description}
        </DescriptionText>
      }
    </Wrapper>
  </ShadeBox>
);

export default HeadlineWidget;