import React from "react";
import Flex from "components/FlexWrapper";
import PageFooterAbout6ix from "./PageFooterAbout6ix";
import PageFooterDisclamer from "./PageFooterDisclamer";

const PageFooterContentRight = () => {
  return (
    <Flex className="ads6ix-layout-footer-content-right">
      <PageFooterAbout6ix />
      <PageFooterDisclamer />
    </Flex>
  );
};

export default PageFooterContentRight;
