import { Box, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Wrapper, Section } from "./style";
import CardBox from "components/CardBox";
import FeaturesListColumn from "components/FeaturesListColumn";
import FeaturesList from "components/FeaturesList";
import { TickIcon } from "components/Icons/TickIcon";

const ListingSection = ({pTop, pBottom, bgColor}: any) => (
  <Section
    className="ListingSection"
    sx={{
      backgroundColor: `${bgColor}`,
      paddingTop: `calc(${pTop} * 0.52)`,
      paddingBottom: `calc(${pBottom} * 0.52)`,

      "@media (min-width: 768px)": {
        paddingTop: `calc(${pTop} * 0.8)`,
        paddingBottom: `calc(${pBottom} * 0.8)`,
      },
      "@media (min-width: 1200px)": {
        paddingTop: `calc(${pTop} * 1)`,
        paddingBottom: `calc(${pBottom} * 1)`
      }
    }}
  >
    <Wrapper>
      <CardBox>
        <FeaturesListColumn heading="6ix Leads is the only platform with&hellip;" hasBorder asIndividual>
          <FeaturesList className="no-bg">
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Investor-Centric Targeting
              </Typography>
              <ListItemText
                primary="While traditional B2B or B2C campaigns might cast a wide net, 6ix Leads specializes in pinpointing the most relevant investors. Using our advanced algorithms trained on 6ix’s proprietary datasets spanning 2,000+ institutional investors, 10,000+ accredited investors and 100,000+ retail investors, 6ix Leads ensure that you're reaching stakeholders genuinely interested in your financial story, driving investor demand."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Goal-Oriented Ad Designs
              </Typography>
              <ListItemText
                primary="Unlike generic campaigns that aim for brand awareness or clicks, 6ix Leads focuses on investor-specific outcomes. Whether your goal is to schedule more investor meetings, boost downloads of IR presentations, or increase engagement with factsheets, our ad designs are crafted to prompt these specific actions, aligning with your IR objectives."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TickIcon />
              </ListItemIcon>
              <Typography
                variant="h3"
                className="list-item-heading"
              >
                Measurable Investor Impact
              </Typography>
              <ListItemText
                primary="In the world of B2C or B2B, metrics like impressions or click-through rates might suffice. But with 6ix Leads, delve deeper into investor behaviors. Track metrics like investor meeting requests, presentation downloads, and active engagement with financial data, offering a more comprehensive view of your campaign's success."
              />
            </ListItem>
          </FeaturesList>
        </FeaturesListColumn>
      </CardBox>
    </Wrapper>
  </Section>
);

export default ListingSection;