import React from "react";
import { Link } from "react-router-dom";
import Flex from "components/FlexWrapper";

const PageFooterMenu = () => {
  return (
    <Flex className="ads6ix-layout-footer-menu">
      <Link className="ads6ix-layout-footer-menu-link" to="/">
        Home
      </Link>
      <a
        className="ads6ix-layout-footer-menu-link"
        href="https://6ix.com/about/"
      >
        About
      </a>
      <a
        className="ads6ix-layout-footer-menu-link"
        href="https://6ix.com/careers/"
      >
        Careers
      </a>
      <a
        className="ads6ix-layout-footer-menu-link"
        href="https://6ix.com/security/"
      >
        Security
      </a>
    </Flex>
  );
};

export default PageFooterMenu;
