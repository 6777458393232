import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "context";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { Box } from "@mui/material";
import Flex from "components/FlexWrapper";
import { BASE_SITE_URL } from "config";

const HeaderSidebar = () => {
  const { setOpenSidebar } = useContext(AppContext);
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    setActiveClass("active");
    return () => setActiveClass("");;
  }, [isMobile]);

  return isMobile ? (
    <React.Fragment>
      <Box className={`ads6ix-layout-sidebar  ${activeClass}`}>
        <Flex direction="column" className="ads6ix-layout-sidebar-inner">
          <Box className="ads6ix-layout-sidebar-body">
            <Box className="ads6ix-layout-sidebar-header">
              Invest Capital
            </Box>
            <a
              href={`${BASE_SITE_URL}/explore`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Explore the Marketplace
            </a>
            <a
              href="https://ai.6ix.com"
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Chat with 6ix AI
            </a>
            <a
              href={`${BASE_SITE_URL}/6ix/meeting`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Book 1:1 Meetings
            </a>
            <div className="ads6ix-layout-sidebar-divider"></div>

            <Box className="ads6ix-layout-sidebar-header">
              Attract Capital
            </Box>

            <a
              href="https://leads.6ix.com"
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Get Leads
            </a>
            <a
              href={`${BASE_SITE_URL}/create`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Host Events
            </a>
            <a
              href="https://meetings.6ix.com"
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Schedule Meetings
            </a>
            <div className="ads6ix-layout-sidebar-divider"></div>

            <Box className="ads6ix-layout-sidebar-header">
              Share Expertise
            </Box>
            <a
              href={`${BASE_SITE_URL}/6ix/broadcast`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Broadcast Interviews
            </a>
            <div className="ads6ix-layout-sidebar-divider"></div>
            <a
              href={`${BASE_SITE_URL}/support`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Contact sales
            </a>
            <a
              href={`${BASE_SITE_URL}/6ix/demo`}
              className="ads6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              View demo
            </a>
          </Box>
        </Flex>
      </Box>

      <div
        className="ads6ix-layout-sidebar-overlay"
        onClick={() => setOpenSidebar(false)}
      ></div>
    </React.Fragment>
  ) : null;
};

export default HeaderSidebar;
