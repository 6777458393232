import React from "react";
import { Link } from "react-router-dom";
import Flex from "components/FlexWrapper";
import { BASE_SITE_URL } from "config";

const PageFooterContentAttract = () => {

  return (
    <Flex className="ads6ix-layout-footer-content-left-row">
      <div className="ads6ix-layout-footer-content-left-row-content">
        <div className="ads6ix-layout-footer-content-left-row-content-heading">
          ATTRACT CAPITAL
        </div>
        <div className="ads6ix-layout-footer-content-left-row-content-text">
          <div className="ads6ix-layout-footer-content-space">
            Tools to help you attract capital.
          </div>
          <div className="ads6ix-layout-footer-content-space">
            <ul>
              <li>
                Efficiently raise capital and build a global investor base
              </li>
              <li>Reach a wider pool of potential investors</li>
              <li>Tell your story effectively to the right audience</li>
              <li>Build and maintain relationships with investors</li>
              <li>Access capital more quickly and at lower cost</li>
              <li>Gain valuable market intelligence and feedback</li>
            </ul>
          </div>
          <div className="ads6ix-layout-footer-content-space">
            Trusted by 400+ public and private companies.
          </div>
        </div>
      </div>
      <Flex className="ads6ix-layout-footer-content-left-row-menu">
        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Reach investors
          </div>

          <a
            href="https://leads.6ix.com"
            className="ads6ix-layout-footer-content-left-row-menu-box-link"
          >
            Get Leads
          </a>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Engage investors
          </div>

          <a
            href={`${BASE_SITE_URL}/create`}
            className="ads6ix-layout-footer-content-left-row-menu-box-link"
          >
            Host Events
          </a>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            Convert Investors
          </div>

          <a
            href="https://meetings.6ix.com"
            className="ads6ix-layout-footer-content-left-row-menu-box-link"
          >
            Book Meetings
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageFooterContentAttract;
