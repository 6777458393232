import { styled, Box, Typography, Button } from '@mui/material';

export const DraftWrapper = styled('section')`
  width: 100%;
  max-width: 1091px;
  overflow: hidden;
  background-color: #131313;
  padding: 20px;
  margin: 100px auto 40px;
  border: 1px solid rgb(43, 43, 43);
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  > div {
    padding: 10px;
    display: flex;
    flex-direction: column;

    + div {
      border-top: 1px solid rgb(43, 43, 43);
    }
  }

`;

export const Wrapper = styled(Button)`
  min-width: 108px;
  background-color: #2b2b2b;
  color: #E9E9E9;
  text-transform: capitalize;
  border-radius: 3px;
  border: 1px solid #373737;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  padding: 12px;
  justify-content: flex-start;

  &:hover{
    background-color: #454648;
    color: #E9E9E9;
    border-color: #E3C050;
  }

  &:disabled {
    opacity: 0.75;
    color: #E9E9E9;
  }

  .MuiSvgIcon-root {
    font-size: 30px;
  }
`;

export const ImageHolder = styled(Box)`
  margin: 0 15px 0 0;
  overflow: hidden;
  border-radius: 9px;
  
`;

export const Heading = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.25;
`;