import React from "react";
import { Link } from "react-router-dom";
import Flex from "components/FlexWrapper";
import { BASE_SITE_URL } from "config";

const PageFooterContentInvest = () => {
  return (
    <Flex className="ads6ix-layout-footer-content-left-row">
      <div className="ads6ix-layout-footer-content-left-row-content">
        <div className="ads6ix-layout-footer-content-left-row-content-heading">
          Invest Capital
        </div>
        <div className="ads6ix-layout-footer-content-left-row-content-text">
          <div className="ads6ix-layout-footer-content-space">
            Tools to help you invest better.
          </div>
          <div className="ads6ix-layout-footer-content-space">
            <ul>
              <li>Discover and access diverse global investment events</li>
              <li>
                Engage directly with company management through video-first
                events
              </li>
              <li>Access detailed, trustworthy information about events</li>
              <li>Learn from other investors and analysts</li>
            </ul>
          </div>
          <div className="ads6ix-layout-footer-content-space">
            Trusted by 100,000+ retail, accredited & institutional investors.
          </div>
        </div>
      </div>
      <Flex className="ads6ix-layout-footer-content-left-row-menu">
        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            DISCOVER
          </div>

          <a
            href={`${BASE_SITE_URL}/explore`}
            className="ads6ix-layout-footer-content-left-row-menu-box-link"
          >
            Explore the Marketplace
          </a>

          <a
            href="https://ai.6ix.com"
            className="ads6ix-layout-footer-content-left-row-menu-box-link"
          >
            Chat with 6ix AI
          </a>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            ENGAGE
          </div>
        </Flex>

        <Flex className="ads6ix-layout-footer-content-left-row-menu-box">
          <div className="ads6ix-layout-footer-content-left-row-menu-box-heading">
            TRANSACT
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PageFooterContentInvest;
