import React from "react";

const PageFooterAbout6ix = () => {
  return (
    <div className="ads6ix-layout-footer-content-right-box">
      <div className="ads6ix-layout-footer-content-right-box-heading">
        ABOUT 6ix
      </div>
      <div className="ads6ix-layout-footer-content-right-box-text">
        <div className="ads6ix-layout-footer-content-space">
          6ix Inc. is a financial technology company on a mission to unlock
          social mobility on a global scale by empowering anyone from anywhere
          to invest, attract capital and share expertise.
        </div>
        <div className="ads6ix-layout-footer-content-space">
          Our flagship product - 6ix.com - combines the power of community and
          technology by making it easy for anyone to attend, host, and market
          virtual investment summits.
        </div>
        <div className="ads6ix-layout-footer-content-space">
          Virtual investment summits are live, interactive events delivered over
          the internet by video, and are specifically designed around the unique
          capital markets needs of investors, companies and experts.
        </div>
        Virtual investment summits are powerful because they function as
        time-based marketplaces that bring people together at the exact moment
        when investment decisions are being made.
      </div>
    </div>
  );
};

export default PageFooterAbout6ix;
